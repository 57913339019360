import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { ModalHeader } from 'react-bootstrap';
import { Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItem, ListItemText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from 'react-bootstrap/Button';
import IconButton from '@mui/material/IconButton';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import DropDown from '../../component-part/dropDown';
import LongAnswerImg from '../../../img/question-and-answer.png';
import ShortAnswerImg from '../../../img/question.png';
import RatingImg from '../../../img/rating.png';
import DropdownImg from '../../../img/drop-down-menu.png';
import LinearscaleImg from '../../../img/customer-satisfaction.png';
import RangeSliderImg from '../../../img/compass.png';
import FileUploadImge from '../../../img/upload-file.png';
import ImageChoiceImg from '../../../img/photo.png';
import DateTimeImg from '../../../img/calendar.png';
import MultipleChoiceImg from '../../../img/multiple.png';
import MatrixMCQImg from '../../../img/dot-matrix-chart.png';
import MatrixCheckImg from '../../../img/matrix.png';
import {
  closeAIquestion,
  createSurveypopup,
  showBackdropLoader,
  closeBackdroploader,
  createQueAlert,
  createQueAlertclose,
  updateQuestionList,
} from '../../../Redux/actions';
import '../../../Style/AIquestionModal.css';
import { BASE_URL, API_ENDPOINTS } from '../../../api_folder/config';

const AIquestionModal = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const showModal = useSelector((state) => state.showAIquestion);
  const [addedQuestions, setAddedQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [topic, setTopic] = useState('');
  const [questions, setQuestions] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [error, setError] = useState(null);
  const [generatedQuestionsHistory, setGeneratedQuestionsHistory] = useState([]);
  const counter = useSelector((state) => state.showsurveyloaderbackdrop);


  const handleClose = () => {
    dispatch(closeAIquestion());
    setQuestions([]);
    setExpanded(false);
    setGeneratedQuestionsHistory([]);
    setAddedQuestions([]);
    setTopic('');

  };

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };


  const handleGenerate = async () => {
    if (!topic || topic.trim() === '' || !/^[\dA-Za-z\s]+$/.test(topic)) {
      setError('Please enter a valid topic.');
      setIsLoading(false); 
      return;
    }
    
  
    setIsLoading(true);
    const apiKey = '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'; // Replace with your actual API key
  
    const prompt = `Generate 30 Survey questions on the topic: ${topic}. Specify the type of question as MC for Multiple Choice, DD for Dropdown, SA for Short Answer, LA for Long Answer, RT for Rating, and LS for Linear Scale. For example:\n
      1. What is the capital city of Spain? [MC]; A) Madrid B) Barcelona C) Seville D) Valencia\n
      2. Which famous Spanish artist is known for his surrealist paintings? [DD]; A) Pablo Picasso B) Salvador Dalí C) Diego Velázquez D) Francisco Goya\n
      3. What is your name? [SA]\n
      4. Describe the impact of climate change in urban areas. [LA]\n
      5. Rate your experience with our service. [RT]\n
      6. How likely would you be to recommend our product to your friend or a colleague? [LS]\n
      7. How many hours do you work out every week? [RS]\n
      8. Which date & time would you like to pick? [DT]\n
      ... (add 22 more questions in total)
      **Exclude previously generated questions:** ${generatedQuestionsHistory.map(q => q.question).join(', ')}`;
  
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content:
                'You are an AI Quiz generator working at TriviaMaker, skilled in generating entertaining questions and answers.',
            },
            {
              role: 'user',
              content: prompt,
            },
          ],
          temperature: 0.3,
          max_tokens: 2048,
          top_p: 1.0,
          frequency_penalty: 0.0,
          presence_penalty: 0.0,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log('API Response:', data);
  
      const generatedQuestions = data.choices[0].message.content
        .split('\n')
        .map((line) => {
          const matchWithOptions = line.match(
            /^\d+\. (.+?) \[(MC|DD|RT|LS|RS|DT)\];?(?: A\) (.+?) ?)?(?: B\) (.+?) ?)?(?: C\) (.+?) ?)?(?: D\) (.+?) ?)?$/
          );
          const matchWithoutOptions = line.match(/^\d+\. (.+?) \[(SA|LA)\]$/);
  
          if (matchWithOptions) {
            const question = matchWithOptions[1].trim();
            let questionType;
            let options;
  
            switch (matchWithOptions[2]) {
              case 'MC':
                questionType = '12';
                options = {
                  A: matchWithOptions[3]?.trim() || '',
                  B: matchWithOptions[4]?.trim() || '',
                  C: matchWithOptions[5]?.trim() || '',
                  D: matchWithOptions[6]?.trim() || '',
                };
                break;
              case 'DD':
                questionType = '4';
                options = {
                  A: matchWithOptions[3]?.trim() || '',
                  B: matchWithOptions[4]?.trim() || '',
                  C: matchWithOptions[5]?.trim() || '',
                  D: matchWithOptions[6]?.trim() || '',
                };
                break;
              case 'RT':
                questionType = '3';
                options = null;
                break;
              case 'LS':
                questionType = '5';
                options = null;
                break;
              case 'RS':
                questionType = '6';
                options = null;
                break;
              case 'DT':
                questionType = '7';
                options = null;
                break;
              default:
                return null;
            }
  
            return {
              question,
              options,
              questionType,
            };
          }
  
          if (matchWithoutOptions) {
            const question = matchWithoutOptions[1].trim();
            const questionType = matchWithoutOptions[2] === 'SA' ? '2' : '1'; // 2 for Short Answer, 1 for Long Answer
            return {
              question,
              options: null,
              questionType,
            };
          }
  
          return null;
        })
        .filter(Boolean);
  
      console.log('Generated Questions:', generatedQuestions);
  
      setGeneratedQuestionsHistory((prevHistory) => [...prevHistory, ...generatedQuestions.map(q => ({ question: q.question, questionType: q.questionType }))]);
      
      setQuestions(generatedQuestions);
    } catch (error) {
      console.error('Error generating questions:', error);
      setError('Failed to generate questions. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  // const handleGenerate = async () => {
  //   setIsLoading(true);
  //   const apiKey = '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'; // Replace with your actual API key
  //   const prompt = `Generate 30 Survey questions on the topic: ${topic}. Specify the type of question as MC for Multiple Choice, DD for Dropdown, SA for Short Answer, LA for Long Answer, RT for Rating, and LS for Linear Scale. For example:\n
  //     1. What is the capital city of Spain? [MC]; A) Madrid B) Barcelona C) Seville D) Valencia\n
  //     2. Which famous Spanish artist is known for his surrealist paintings? [DD]; A) Pablo Picasso B) Salvador Dalí C) Diego Velázquez D) Francisco Goya\n
  //     3. What is your name? [SA]\n
  //     4. Describe the impact of climate change in urban areas. [LA]\n
  //     5. Rate your experience with our service. [RT]\n
  //     6. How likely would you be to recommend our product to your friend or a colleague? [LS]\n
  //     7. How many hours do you work out every week? [RS]\n
  //     8. which date & time you would like to pick? [DT]\n
  //     ... (add 22 more questions in total)`;

  //   try {
  //     const response = await fetch('https://api.openai.com/v1/chat/completions', {
  //       method: 'POST',
  //       headers: {
  //         Authorization: `Bearer ${apiKey}`,
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         model: 'gpt-3.5-turbo',
  //         messages: [
  //           {
  //             role: 'system',
  //             content:
  //               'You are an AI Quiz generator working at TriviaMaker, skilled in generating entertaining questions and answers.',
  //           },
  //           {
  //             role: 'user',
  //             content: prompt,
  //           },
  //         ],
  //         temperature: 0.3,
  //         max_tokens: 2048,
  //         top_p: 1.0,
  //         frequency_penalty: 0.0,
  //         presence_penalty: 0.0,
  //       }),
  //     });

  //     if (!response.ok) {
  //       throw new Error(`Error ${response.status}: ${response.statusText}`);
  //     }

  //     const data = await response.json();
  //     console.log('API Response:', data);

  //     const generatedQuestions = data.choices[0].message.content
  //       .split('\n')
  //       .map((line) => {
  //         const matchWithOptions = line.match(
  //           /^\d+\. (.+?) \[(MC|DD|RT|LS|RS|DT)\];?(?: A\) (.+?) ?)?(?: B\) (.+?) ?)?(?: C\) (.+?) ?)?(?: D\) (.+?) ?)?$/
  //         );
  //         const matchWithoutOptions = line.match(/^\d+\. (.+?) \[(SA|LA)\]$/);

  //         if (matchWithOptions) {
  //           const question = matchWithOptions[1].trim();
  //           let questionType;
  //           let options;

  //           switch (matchWithOptions[2]) {
  //             case 'MC':
  //               questionType = '12';
  //               options = {
  //                 A: matchWithOptions[3]?.trim() || '',
  //                 B: matchWithOptions[4]?.trim() || '',
  //                 C: matchWithOptions[5]?.trim() || '',
  //                 D: matchWithOptions[6]?.trim() || '',
  //               };
  //               break;
  //             case 'DD':
  //               questionType = '4';
  //               options = {
  //                 A: matchWithOptions[3]?.trim() || '',
  //                 B: matchWithOptions[4]?.trim() || '',
  //                 C: matchWithOptions[5]?.trim() || '',
  //                 D: matchWithOptions[6]?.trim() || '',
  //               };
  //               break;
  //             case 'RT':
  //               questionType = '3';
  //               options = null;
  //               break;
  //             case 'LS':
  //               questionType = '5';
  //               options = null;
  //               break;
  //             case 'RS':
  //               questionType = '6';
  //               options = null;
  //               break;
  //             case 'DT':
  //               questionType = '7';
  //               options = null;
  //               break;
  //             default:
  //               return null;
  //           }

  //           return {
  //             question,
  //             options,
  //             questionType,
  //           };
  //         }

  //         if (matchWithoutOptions) {
  //           const question = matchWithoutOptions[1].trim();
  //           const questionType = matchWithoutOptions[2] === 'SA' ? '2' : '1'; // 2 for Short Answer, 1 for Long Answer
  //           return {
  //             question,
  //             options: null,
  //             questionType,
  //           };
  //         }

  //         return null;
  //       })
  //       .filter(Boolean);

  //     console.log('Generated Questions:', generatedQuestions);

  //     setQuestions(generatedQuestions);
  //   } catch (error) {
  //     console.error('Error generating questions:', error);
  //     setError('Failed to generate questions. Please try again.');
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleAddButtonClick = async (question) => {
    const getOptionsArray = (question) => {
      switch (question.questionType) {
        case '3': // Rating
          return ['5', '2', null, ['1', '2', '3', '4', '5']];
        case '5': // Linear Scale
          return [1, 10];
        case '6': // RS
          return ['10', '20', '30'];
        case '7': // Date and Time
          return ['true', 'true', 'DD / MM / YYYY'];
        default:
          return question.options
            ? Object.values(question.options).map((option) => option.replace(/^\s*[A-D]\)\s*/, '').trim())
            : [];
      }
    };
    setAddedQuestions([...addedQuestions, question]);
    const createQuestionPayload = (question, jsonString, createdDate, modifiedDate) => ({
      questionid: generateQuestionId(),
      surveyid: id,
      question_text: question.question.replace(/^\d+\.\s*/, '').trim(),
      question_type: question.questionType,
      created_date: createdDate,
      modified_date: modifiedDate,
      isImage: 0,
      imagelink: 0,
      isVideo: 0,
      video_link: 0,
      isAudio: 0,
      audio_link: 0,
      answer: jsonString,
      multiple_answer: 0,
      compulsory: 0,
    });

    const updateQuestionOrder = async (questionId) => {
      const orderEndpoint = API_ENDPOINTS.surveyEdit;
      const currentOrder = localStorage.getItem('quesOrder') || '';
      const orderArray = currentOrder.split(',').map((item) => item.trim());
      orderArray.push(questionId);

      const response = await fetch(`${BASE_URL}${orderEndpoint}?surveyid=${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ question_order: JSON.stringify(orderArray.map(String)) }),
      });

      if (response.status === 200) {
        console.log(`Survey with ID ${id} question order saved successfully.`);
      } else {
        console.error('Failed to update question order');
      }
    };

    setIsLoading(true);
    try {
      const optionsArray = getOptionsArray(question);
      const jsonString = JSON.stringify(optionsArray);

      const now = new Date();
      const createdDate = `${now.toLocaleDateString()} ${now.toLocaleTimeString()}`;
      const modifiedDate = createdDate;

      const requestBody = createQuestionPayload(question, jsonString, createdDate, modifiedDate);
      const response = await fetch(`${BASE_URL}${API_ENDPOINTS.questionCreatePOST}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 200) {
  
        setTimeout(() => dispatch(createQueAlertclose()), 1000);
        dispatch(createQueAlert());
        dispatch(updateQuestionList());

        console.log('Survey Data posted successfully');
        await updateQuestionOrder(requestBody.questionid);
      } else {
        alert('Survey not created due to some issues');
        console.error('Failed to create question:', response.statusText);
      }
    } catch (err) {
      alert('Survey not created due to some issues');
      console.error('Error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const generateQuestionId = () => {
    return Math.random().toString().slice(2, 7);
  };

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: isLoading ? 2000 : undefined }} open={isLoading}>
        <CircularProgress color="inherit" variant="indeterminate" />
      </Backdrop>
      <Modal
        open={showModal}
        onClose={handleClose}
        className="AiQuestionModal "
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className="modal-content" style={{ width: '70%' }}>
          <ModalHeader
            className="modal-header"
            style={{
              backgroundImage: 'linear-gradient(#ffffff, #ffffff)',
              alignItems: 'center',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
            }}
          >
            <h3
              id="addstudentcsv"
              style={{
                margin: '25px',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              AI Question Generator
            </h3>
            <IconButton onClick={handleClose} style={{ position: 'absolute', right: '15px' }} aria-label="close">
              <AiOutlineClose />
            </IconButton>
          </ModalHeader>
          <div
            className="modal-body gamemodalcontainer gamemodalborder"
            style={{ backgroundColor: '#e0e0e0', borderRadius: '0px' }}
          >
            <div className="collectioncontent" style={{ padding: '10px' }}>
              <div className="searchbar d-flex justify-content-between mt-0" style={{ width: '100%' }}>
                <input
                  className="form-control"
                  id="myInput"
                  type="text"
                  placeholder="Enter Topic Name (eg: Geography)"
                  value={topic}
                  onChange={(e) => {
                    setTopic(e.target.value);
                    // Clear error message on input change
                    if (e.target.value.trim() !== '') {
                      setError('');
                    }
                  }}
                  style={{
                    fontStyle: 'italic',
                    fontSize: '18px',
                    width: '100%',
                    borderTopRightRadius: '0px',
                    borderBottomRightRadius: '0px',
                    marginBottom: '7px',
                  }}
                />
                <Button
                  className="bgButton"
                  variant="contained"
                  color="success"
                  id="generatebtn"
                  onClick={handleGenerate}
                  style={{
                    marginLeft: '10px',
                    height: '40px',
                  }}
                >
                  Generate
                </Button>
              </div>
            </div>
            <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
              {questions.map((question, index) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}a-content`}
                    id={`panel${index}a-header`}
                  >
        <Typography style={{display:'flex',alignItems:'center'}}>
  {question.questionType === '12' && (
    <img src={MultipleChoiceImg} alt="Multiple Choice" className="question-icon"  style={{height:'30px',width:'30px',marginRight:'10px'}}/>
  )}
  {question.questionType === '2' && (
    <img src={ShortAnswerImg} alt="Short Answer" className="question-icon" style={{height:'30px',width:'30px',marginRight:'10px'}}/>
  )}
  {question.questionType === '1' && (
    <img src={LongAnswerImg} alt="Long Answer" className="question-icon" style={{height:'30px',width:'30px',marginRight:'10px'}}/>
  )}
  {question.questionType === '4' && (
    <img src={DropdownImg} alt="Drop Answer" className="question-icon" style={{height:'30px',width:'30px',marginRight:'10px'}}/>
  )}
  {question.questionType === '6' && (
    <img src={RangeSliderImg} alt="range Answer" className="question-icon" style={{height:'30px',width:'30px',marginRight:'10px'}}/>
  )}
  {question.questionType === '3' && (
    <img src={RatingImg} alt="Rating Answer" className="question-icon" style={{height:'30px',width:'30px',marginRight:'10px'}}/>
  )}
  {question.questionType === '7' && (
    <img src={DateTimeImg} alt="Date & time Answer" className="question-icon" style={{height:'30px',width:'30px',marginRight:'10px'}}/>
  )}
  {question.questionType === '5' && (
    <img src={LinearscaleImg} alt="Linear Answer" className="question-icon" style={{height:'30px',width:'30px',marginRight:'10px'}}/>
  )}
  

  {index + 1}. {question.question}
</Typography>

                    <div style={{ marginLeft: 'auto' }}>
                      {!addedQuestions.includes(question) && (
                        <Button
                          variant="contained"
                          className="bgButton"
                          color="success"
                          onClick={(event) => {
                            event.stopPropagation(); // Prevent accordion from collapsing
                            handleAddButtonClick(question);
                          }}
                        >
                          Add
                        </Button>
                      )}
                    </div>
                  </AccordionSummary>

                  <AccordionDetails>
                    {question.options && ( // Render options only if they exist
                      <div
                        id="collapse0"
                        className="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordionExample"
                       
                      >
                        <ul
                          style={{marginBottom:'0px !important',display:'grid',gridTemplateColumns: 'auto auto',}}
                          className="card-body option ml-3"
                          id="questionul0"
                        >
                          <ListItem>
                          <ListItemText primary={`a. ${question.options.A}`} />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={`b. ${question.options.B}`} />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={`c. ${question.options.C}`} />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary={`d. ${question.options.D}`} />
                        </ListItem>
                        </ul>
                       
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
            {error && <div style={{ color: 'red' }}>{error}</div>}
          </div>
          <div
            className="py-4"
            style={{
              backgroundColor: '#ffffff',
              textAlign: 'center',
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
            }}
          >
            <button
              className="addgametoclass"
              data-dismiss="modal"
              style={{
                width: '100px',
                borderRadius: '5px',
                backgroundColor: '#e3e2e2',
                cursor: 'pointer',
                border: 'none',
                height: '35px',
                marginBottom: '7px',
                color: 'black',
              }}
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AIquestionModal;
